<template>
  <div class="box" v-if="showDetail">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="name"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox">
      <BasicTop
        :basicTop="basicTop"
        :numberrows="'2'"
        v-if="basicTop.data.length != 0"
      />
      <GSteps :List="steps" v-if="this.$route.query.appStatus == 1" />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="供应商" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item
                  title="违约信息审核"
                  name="1"
                  icon="label"
                  v-if="details"
                >
                  <van-cell-group
                    inset
                    v-for="(item, index) in details"
                    :key="index"
                    @click="handleAudit(item)"
                  >
                    <p class="title">{{ item.supplierName }}</p>
                    <div class="label">
                      供应商编码 : <span>{{ item.supplierCode }}</span>
                    </div>
                    <div class="label">
                      项目及装置名称 : <span>{{ item.projectdeviceName }}</span>
                    </div>
                    <div class="label">
                      物资名称/规格 : <span>{{ item.materialname }}</span>
                    </div>
                    <div class="label">
                      违约类型 :
                      <span>{{ getTypeName(item.handleOpinion) }}</span>
                    </div>
                    <div class="label">
                      处理意见 :
                      <span>{{ getHandleTypeName(item.handleType) }}</span>
                    </div>
                    <div class="label" style="margin-bottom: 10px">
                      审批意见 :
                      <span>{{
                        item.expand.status
                          ? item.expand.status == 0
                            ? "同意"
                            : "不同意"
                          : "未审批"
                      }}</span>
                    </div>
                    <div class="arrow" v-if="isLastTask && item.isArrow">
                      <p class="arrow-name">
                        审批<van-icon class="arrow-icon" name="arrow" />
                      </p>
                    </div>
                  </van-cell-group>
                </van-collapse-item>
                <div v-else>
                  <p style="padding: 30px; text-align: center">暂无数据</p>
                </div>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="相关文件" name="b">
            <van-list :finished="true" v-if="details && files">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="产品目录" name="6" icon="label">
                  <van-cell-group class="group">
                    <van-cell title=""
                      ><div
                        class="preview"
                        v-for="item in files"
                        :key="item.id"
                      >
                        <a :href="item.href">{{ item.file.title }}</a>
                      </div></van-cell
                    >
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
            <div v-else>
              <p style="padding: 30px; text-align: center">暂无数据</p>
            </div>
          </van-tab>
          <!-- 审核历史 -->
          <van-tab title="审批记录" name="c" v-if="isPass">
            <Task :applyId="stockaffirmId" :tableName="2" :applyType="2" />
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="footer" v-if="!isPass">
      <div class="footer-left" @click="zeroAgree" v-if="!isLastTask">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="!isLastTask">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div
        class="footer-left"
        @click="zeroAgree"
        v-if="isLastTask && !basicTop.data.supplierCount"
      >
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div
        class="footer-right"
        @click="noagree"
        v-if="isLastTask && !basicTop.data.supplierCount"
      >
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div
        class="footer-left"
        @click="agree"
        v-if="isLastTask && basicTop.data.supplierCount"
      >
        <span><van-icon name="success" size="20"/></span>
        <span>提交</span>
      </div>
    </div>
    <van-popup v-model="show" round :style="{ height: '30%' }">
      <div class="pop-content">
        <div class="pop-label">审批意见</div>
        <van-radio-group v-model="radio" direction="horizontal">
          <van-radio name="0">同意</van-radio>
          <van-radio name="1">拒绝</van-radio>
        </van-radio-group>
      </div>
      <div style="padding: 10px">
        <van-field
          v-model="message"
          rows="1"
          type="textarea"
          show-word-limit
          style="border: 1px solid #666; border-radius: 5px"
        />
      </div>

      <div class="footer" style="border-top: none; background-color: #fff">
        <div class="footer-left" @click="onCancel">
          <span>取消</span>
        </div>
        <div class="footer-right" @click="onConfirm">
          <span>确认</span>
        </div>
      </div>
    </van-popup>
  </div>
  <div class="box" v-else>
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="name"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <Noagree :onSubmit="auditApply" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import noagree from "../noagree.vue";
export default {
  components: { noagree },
  name: "gsnBreachApplyDetails",
  data() {
    return {
      files: [],
      show: false,
      id: "",
      radio: "0",
      name: "",
      checkFlag: "待审核",
      message: "同意",
      examineFlag: 1,
      showDetail: true,
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      steps: [],
      workflowId: "",
      isLastTask: false,
      basicTop: {
        data: [],
        columns: [
          {
            prop: "applyEnterpriseName",
            label: "提报单位",
          },
          {
            prop: "supplierCount",
            label: "提报数量",
          },
          {
            prop: "submitDate",
            label: "提报日期",
          },
          {
            prop: "checkFlag",
            label: "提报状态",
          },
        ],
      },
    };
  },
  async created() {
    switch (this.$route.query.statusId) {
      case "1":
        this.checkFlag = "审核中";
        break;
      case "2":
        this.checkFlag = "审核通过";
        break;
      case "3":
        this.checkFlag = "审核拒绝";
        break;
      default:
        this.checkFlag = "待审核";
    }
    await this.getDetail();
    if (this.$route.query.appStatus != 2) {
      await this.getTask();
    }
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    isPass() {
      return this.$route.query.appStatus != 1;
    },
  },
  methods: {
    // onPreview(item) {
    //   let query = {
    //     id: item.product.id,
    //     type: item.productTypeId
    //   };
    //   this.$router.push({ name: "previewGsn", query })
    // },
    formatDate(date) {
      let d = new Date(date);
      const month =
        d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      const day = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();
      return `${d.getFullYear()}-${month}-${day}`;
    },
    async getDetail() {
      const params = {
        applyId: this.stockaffirmId,
      };
      let data = await this.$api.gsn.getBreachAuditInfo(params);
      if (data.isLastTask) {
        data.applyDetails.forEach((item) => {
          if (item.expand.opinion) {
            item.isArrow = false;
          } else {
            item.isArrow = true;
          }
        });
      }
      if (data.files) {
        data.files.forEach((item) => {
          let api = "/api-gsn/FileAction!downloadByApp/";
          // let title = item.product.name.substring(
          //   item.product.name.lastIndexOf('.') + 1
          // )
          // if (item.productTypeId == 1) {
          //   api = '/api-gsn/FileAction!showImageByApp/'
          // }
          item.href = `${api}${item.fileId}-${item.file.title}`;
        });
      }
      this.files = data.files;
      console.log(this.files);
      this.name = data.applyEnterpriseName;
      this.workflowId = data.apply.workflowid;
      this.isLastTask = data.isLastTask;
      // 详情头部
      const supplierInfo = {
        submitDate: this.formatDate(data.apply.submitDate),
        applyEnterpriseName: data.applyEnterpriseName,
        supplierCount: data.applyDetails ? data.applyDetails.length : 0,
        checkFlag: this.checkFlag,
      };
      this.basicTop.data = supplierInfo;
      this.details = data.applyDetails;
    },
    async getTask() {
      const params = {
        workflowId: this.workflowId,
      };
      let data = await this.$api.gsn.getTaskList(params);
      this.steps = data;
    },
    getTypeName(id) {
      const handleOpinionName = [
        {
          id: "05",
          name: "通报降级",
        },
        {
          id: "06",
          name: "交易资格停用一年",
        },
        {
          id: "07",
          name: "取消交易资格",
        },
        {
          id: "08",
          name: "风险停用六个月",
        },
        {
          id: "09",
          name: "交易资格停用六个月",
        },
      ];

      let name = "";
      handleOpinionName.forEach((item) => {
        if (item.id == id) {
          name = item.name;
        }
      });
      return name;
    },
    getHandleTypeName(id) {
      const handleTypeName = [
        {
          id: "01",
          name: "服务",
        },
        {
          id: "02",
          name: "质量",
        },
        {
          id: "03",
          name: "价格",
        },
        {
          id: "04",
          name: "延期交货",
        },
        {
          id: "05",
          name: "不诚信",
        },
        {
          id: "06",
          name: "廉洁从业",
        },
        {
          id: "99",
          name: "其他",
        },
        {
          id: "99",
          name: "其他",
        },
      ];
      let arr = id.split(",");
      let name = "";
      arr.forEach((item) => {
        handleTypeName.forEach((vos) => {
          if (item == vos.id) {
            name += vos.name;
          }
        });
      });
      return name;
    },
    onClickLeft() {
      if (this.showDetail) {
        this.$router.go(-1);
      } else {
        this.showDetail = true;
      }
    },
    onRefresh() {
      this.loading = true;
    },
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.auditApply(1);
        })
        .catch(() => {
          // on cancel
        });
    },
    zeroAgree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.moreAuditApply(this.stockaffirmId);
        })
        .catch(() => {
          // on cancel
        });
    },
    async moreAuditApply(ids) {
      this.$api.gsn.auditZeroBreachApplys(ids).then(() => {
        Toast.success("提交成功");
        let query = {
          audit: 1,
        };
        this.$router.push({ name: "gsnBreachApply", query });
      });
    },
    noagree() {
      this.showDetail = false;
    },
    async auditApply(flag = 2) {
      let submit = true;
      let params = {
        applyId: this.stockaffirmId,
        isLastTask: this.isLastTask,
        auditFlag: flag,
        auditOpinion: 12,
        workflowId: this.workflowId,
      };
      if (this.isLastTask) {
        let supplierDetailAuditJson = [];
        if (flag != 2) {
          this.details.forEach((item) => {
            if (!item.expand.status || !item.expand.opinion) {
              submit = false;
            }
            const d = {
              auditDetailResult: item.expand.status,
              auditDetailOpinion: item.expand.opinion,
              supplierId: item.expand.id,
            };
            supplierDetailAuditJson.push(d);
          });
        }
        params = {
          ...params,
          supplierDetailAuditJson,
        };
      }
      if (submit) {
        let data = await this.$api.gsn.auditAssessmentApply(params);
        console.log(data);
        Toast.success("提交成功");
        let query = {
          audit: 1,
        };
        this.$router.push({ name: "gsnBreachApply", query });
      } else {
        Toast.fail("部分关联方未审批");
      }
      this.showDetail = true;
    },
    handleAudit(item) {
      if (this.isLastTask && item.isArrow) {
        this.show = true;
        this.id = item.id;
        this.radio = item.expand.status ? item.expand.status : "0";
        this.message = item.expand.opinion
          ? item.expand.opinion == 0
            ? "同意"
            : item.expand.opinion
          : "同意";
      }
    },
    onConfirm() {
      this.details.forEach((item) => {
        if (item.id == this.id) {
          item.expand.status = this.radio;
          item.expand.opinion = this.message;
        }
      });
      this.onCancel();
    },
    onCancel() {
      this.show = false;
      this.radio = "0";
      this.message = "同意";
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
  background-color: transparent;
}
/deep/.van-cell__value {
  color: #333;
}
/deep/.van-cell::after {
  border: none;
}
/deep/.van-cell-group--inset {
  margin-bottom: 10px;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
.texts {
  padding: 10px 20px;
  text-indent: 2em;
  line-height: 24px;
}
.title {
  padding: 10px;
  margin-left: 20px;
  color: #333;
  font-weight: bold;
}
.pop-content {
  width: 300px;
  display: flex;
  .pop-label {
    padding: 20px;
  }
}
.preview {
  color: blue;
}
.label {
  padding: 2px 10px;
  color: #666;
}
.arrow {
  position: absolute;
  top: 35%;
  right: 10px;
  .arrow-name {
    position: relative;
    width: 22px;
    text-align: center;
    .arrow-icon {
      position: absolute;
      top: 40%;
    }
  }
}
</style>
